<template>
    <o-data-lookup :data-object="dsLessonsLearned" contextField="IdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                
            </span>
        </template>
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="300"></o-column>
        <o-column field="Summary" width="300"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>    
import { getOrCreateDataObject } from 'o365.vue.ts'

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsLessonsLearned = getOrCreateDataObject({
    id: 'dsLessonsLearned',
    viewName: 'aviw_Quality_LessonsLearned',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "OrgUnit", type: "string" },
        { name: "Name", type: "string" },
        { name: "Summary", type: "string" },        
    ]
});
</script>